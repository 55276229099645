import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";

export function redirectOrCatch() {
  return asyncStacktrace((err) => {
    if (err.status === 404 || err.status === 403) {
      const findString = /\/(api|wg)\/\S+/.exec(err.message);
      const urlString = findString ? findString[0] : 'A request'
      console.info(`${urlString} returned ${err.status}, redirecting`);
      window.history.replaceState(null, "", `/#/${err.status}`);
    } else catchSyncStacktrace(err);
  });
}