import { useMemo } from 'react'
import { fetchAsObservable } from 'src/fetcher.js'
import { useObservable } from './use-observable.js'
import { pluck as rxjsPluck } from 'rxjs/operators'

export function useFetcher(url, optionalProps = {}) {
  const {options = {}, pluck, initialResults, manualFire } = optionalProps
  const observable = useMemo(
    () => {
      return fetchAsObservable(
        url,
        options
      ).pipe(
        maybePluck(pluck)
      )
    },
    [url, pluck, JSON.stringify(options)]
  )
  const { loading, results, error, resubscribe, fire } = useObservable(observable, {initialResults, manualFire})
  return {
    loading,
    results,
    error,
    refetch: resubscribe,
    fire,
  }
}

const maybePluck = (pluckValue) => (source) => {
  if (pluckValue) {
    return source.pipe(rxjsPluck(pluckValue))
  } else {
    return source
  }
}
