import { asyncStacktrace, catchSyncStacktrace } from 'auto-trace'
import { useReducer, useEffect, useRef } from 'react'

export function useObservable(obs$, options = {}) {
  const { manualFire = false, initialResults } = options
  const initialState = {loading: false, count: 0, results: initialResults, live: !manualFire}
  const [ state, dispatch ] = useReducer(reducer, initialState)

  const prevCount = useRef(state.count)

  // this effect is for live (or not manually fired requests)
  useEffect(() => {
    if (state.live) {
      dispatch({type: 'loading'})
      const subscription = obs$.subscribe(
        (results) => {
          dispatch({type: 'loaded', results})
        },
        asyncStacktrace((err) => {
          dispatch({type: 'error', error: err})
        })
      )
      return () => {
        subscription.unsubscribe()
      }
    }
  }, [obs$, state.count, state.live])

  // this effect is only for manually fired requests
  useEffect(() => {
    if (!state.live && state.count !== prevCount.current) {
      dispatch({type: 'loading'})
      const subscription = obs$.subscribe(
        (results) => {
          dispatch({type: 'loaded', results})
        },
        asyncStacktrace((err) => {
          dispatch({type: 'error', error: err})
        })
      )
      return () => {
        subscription.unsubscribe()
      }
    }
  }, [state.count, obs$, state.live])

  useEffect(() => {
    if (state.count !== prevCount.current) {
      prevCount.current = state.count
    }
  }, [state.count])

  const returnValue = {
    loading: state.loading,
    results: state.results,
    error: state.error,
    resubscribe: () => dispatch({type: 'resubscribe'}),
  }
  if (manualFire === true) {
    returnValue.fire = () => {
      prevCount.current = state.count
      dispatch({type: 'fire'})
    }
  }
  return returnValue

}

function reducer (state, action) {
  switch (action.type) {
    case 'fire':
    case 'resubscribe':
      return {...state, count: state.count + 1};
    case 'loading':
      return {...state, loading: true}
    case 'loaded':
      return {...state, loading: false, results: action.results}
    case 'error':
      return {...state, loading: false, error: action.error}
    default:
      throw new Error('unmatched action type', action)
  }
}

