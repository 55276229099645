import canopyUrls from 'canopy-urls!sofe';

export function defaultToAPIUrl(url) {
  if (url.startsWith('/') && !url.startsWith('/wg') && !url.startsWith('/api')) {
    // Default to java web gateway
    return canopyUrls.getAPIUrl() + url;
  } else {
    return url
  }
}
