import auth from 'cp-client-auth!sofe';
import { noop } from 'lodash'
import { getAllLocalStorageFeatureToggles } from 'feature-toggles!sofe'
import { captureMessage, captureException } from 'sentry-error-logging!sofe';

export class CanopyError extends Error {}

export const getAbortController = () =>
  typeof AbortController !== "undefined"
    ? new AbortController()
    : { abort: noop, signal: null };

export const handleFetcherResponse = (fetcherPromise, responseType='default', originalError) =>
  fetcherPromise.then(response => {
    if (response.ok) {
      if (response.status === 204) return null; // 204 No Content

      if (responseType === 'default') {
        return response.text()
        .then(text => {
          try {
            return JSON.parse(text);
          } catch(e) {
            return text;
          }
        })
        .catch(handleUnknownError);
      } else {
        return response[responseType]()
        .catch(handleUnknownError);
      }
    } else {
      originalError.message = `Request error: ${response.status} ${response.url} ${response.statusText}`;
      originalError.status = response.status;
      // Try to get the response body and include it in the error
      return response
        .text()
        .then(body => {
          originalError.message += `. ${body}`;
          try {
            originalError.data = JSON.parse(body);
          } catch (err) {
            originalError.data = body;
          }
          // Backend is getting 400s and they can't trace them, they've asked us to log 400s to sentry so they can have more information
          if (response.status === 400) {
            const headers = response.headers
            let err = new Error(`400 network response`)
            err.showToast = false
            captureException(err, {headers, body, tags: {debugging400s: true}})
          }
          throw originalError;
        })
        .catch(() => {
          throw originalError;
        });
    }
  });

export function setHeaders(setHeader) {
  const csrfToken = auth.getCSRFToken();
  if (csrfToken) {
    setHeader('X-CSRF-TOKEN', csrfToken);
  }

  const localStorageFeatureToggles = getAllLocalStorageFeatureToggles()
  if (Object.keys(localStorageFeatureToggles).length) {
    setHeader('X-Canopy-Overrides', JSON.stringify(localStorageFeatureToggles))
  }

  const backendOverride = window.localStorage.getItem('exchange-override')
  if (backendOverride) {
    setHeader('CTX-Exchange-Override', backendOverride)
  }

  setHeader('client_app_name', window.appIsMobile ? 'mobile-web-app' : 'web-app');
}

export function handleUnknownError(error) {
  if (error instanceof CanopyError) throw error;
  error.showToast = false;
  captureMessage(error);
  return Promise.reject(error);
}
